<template>
	<main class="main">
		<div class="container container--small" v-if="crumbs">
			<Crumbs :data="crumbs"></Crumbs>
		</div>
		<section class="main__box news" v-if="news">
			<div class="container container--small">
				<template v-if="news.current.status != 404">
					<h1 class="news__main-title">{{ news.current.name }}</h1>
					<time class="news__time">{{ news.current.createdAt }}</time>
					<img
						:src="news.current.low ? news.current.low : news.current.image"
						:alt="news.current.name"
						class="news__image-full"
					/>
					<span class="news__author">{{ news.current.author }}</span>
					<div
						class="news__main-text editor"
						v-html="news.current.description"
					></div>
					<div class="main__text editor" v-html="news.current.text"></div>
				</template>
				<template v-else>
					<div class="flex flex--center">
						<p class="error-page__text text-gray">
							<span class="visually-hidden">Ошибка:</span>
							<span class="error-page__code">404</span>
							<span class="error-page__text-row">Новость</span>
							<span
								class="error-page__text-row error-page__text-row--text-indent"
								>не найдена</span
							>
						</p>
					</div>
				</template>
			</div>
		</section>

		<div class="bg bg--gray last" v-if="news && news.current.nearby">
			<section class="main__box main__box--big">
				<div class="container">
					<ul class="info">
						<li
							class="info__item"
							v-for="(item, index) in news.current.nearby"
							:key="index"
						>
							<template v-if="item.id">
								<h3 class="main__title">{{ item.name }}</h3>
								<div class="info__box">
									<div
										class="info__image"
										v-if="item.image"
										:style="{ backgroundImage: `url(${item.image})` }"
									></div>

									<div class="info__content">
										<h4 class="info__sub-title" v-if="item.title">
											{{ item.title }}
										</h4>

										<div
											class="info__text editor"
											v-html="item.description"
										></div>

										<div class="info__button-box flex--center">
											<router-link
												:to="{ name: setLink, params: { id: item.id } }"
												class="button"
												>Узнать подробнее</router-link
											>
										</div>
									</div>
								</div>
							</template>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'

export default {
	name: 'NewsItem',

	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},

	components: {
		Crumbs,
	},

	data() {
		return {
			otherNews: null,
		}
	},
	metaInfo() {
		const news = this.$store.getters.SINGLE_NEWS
		if(news){
			return { meta: [{ name: 'description', content: news.meta_description }] }
		} else {
			return { meta: [{ name: 'description', content: 'NONE' }] }
		}
	},
	computed: {
		news() {
			const news = this.$store.getters.SINGLE_NEWS

			if (news) {
				document.title = news.current.name
				news['nearby'] = new Array()

				if (news.current.previous) {
					news.current.nearby.push({
						...news.current.previous,
						name: 'Предыдущая',
					})
				}

				if (news.current.next) {
					news.current.nearby.push({ ...news.current.next, name: 'Следующая' })
				}
			}

			return news
		},

		crumbs() {
			const meta = this.$route.meta
			if (!meta.operator) {
				let crumbs = null
				let newsLink = null
				let text = this.news ? this.news.current.name : ''

				if (meta.holder) {
					newsLink = 'NewsHolder'
				} else if (meta.partner) {
					newsLink = 'NewsPartner'
				}

				crumbs = [
					{
						link: newsLink,
						text: 'Последние новости',
					},
					{
						link: null,
						text,
					},
				]

				return crumbs
			} else {
				return null
			}
		},

		setLink() {
			const meta = this.$route.meta
			let link = null

			if (meta.holder) {
				link = 'NewsItemHolder'
			} else if (meta.partner) {
				link = 'NewsItemPartner'
			}

			return link
		},
	},

	beforeRouteUpdate(to, from, next) {
		this.$store.dispatch('GET_SINGLE_NEWS', {
			id: to.params.id,
			...this.$route.meta,
		})
		next()
	},

	mounted() {
		this.$store.dispatch('GET_SINGLE_NEWS', {
			id: this.id,
			...this.$route.meta,
		})
	},
}
</script>
